import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Tab, Tabs, Box } from '@mui/material'
import Document from '../components/document'
import DocumentFilter from '../components/document-filter'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faUpload } from '@fortawesome/free-solid-svg-icons'
import { API_ENDPOINT, DOC_PENDING, DOC_SENT, normalizeCase, normalizeRut } from '../constants'
import { connect } from 'react-redux'
import { doLogOut } from '../actions/index'
import GenericLoader from '../components/generic-loader'

class DocumentForm extends Component {
    constructor(props) {
        super(props)
        let searchQuery = null;

        if (this.props.searchQuery) {
            searchQuery = this.props.searchQuery;
        }
        else {
            searchQuery = { csg: '', company: '' }
        }

        this.state = {
            canCreateFolder: true,
            displayFolderModal: false,
            displayDocumentsModal: false,
            canUploadFile: true,
            company: {
                documents: []
            },
            folders: [],
            documentTypes: [],
            species: [],
            selectedFolder: {
                documents: []
            },
            searchQuery: searchQuery,
            loading: false,
            selectedTab: "SAG_DOC"
        }
    }

    componentDidMount() {
        this.refreshFolders();
    }

    refreshFolders(options = { company_id: this.props.currentUser.selectedRoleDisplay.company.id, folderType: this.state.selectedTab }) {
        var self = this
        //Mostramos el loading
        this.setState({
            ...this.state,
            loading: true,
            selectedTab: options.folderType
        })

        if (options.company_id === "" || !options.company_id) {
            //Sin filtro de empresa, ponemos nuestra empresa
            options.company_id = this.props.currentUser.selectedRoleDisplay.company.id;
        }

        if (options.folderType === "") {
            options.folderType = this.state.selectedTab
        }
        //Busquemos los documentos
        axios.get(`${API_ENDPOINT}${'company/folder'}`,
            {
                params: options
            }
        )
            .then(function (response) {

                console.log(response.data);
                self.setState({
                    ...self.state,
                    folders: response.data,
                    selectedTab: options.folderType
                })
            }).catch(function (error) {
                if (error.response) {
                    //No estamos conectados, renviar a la pagina de inicio
                    if (error.response.status === 403) {
                        self.props.doLogOut()
                    }
                }
            }).finally(() => {
                self.setState({
                    ...self.state,
                    loading: false,
                    selectedTab: options.folderType
                })
            })
    }

    loadDocumentType(folderType) {
        var self = this

        const filterType = folderType === "BL_DOC" ? "?df=bldoc" : "?df=company"; //Si estamos visualizando documentos de despacho, hay otros tipos de docs
        axios.get(`${API_ENDPOINT}${'company/document/type' + filterType}`)
            .then(function (response) {
                self.setState({
                    ...self.state,
                    documentTypes: response.data
                })
            })
    }

    loadSpecies() {
        var self = this
        axios.get(`${API_ENDPOINT}${'specie'}`)
            .then(function (response) {
                self.setState({
                    ...self.state,
                    species: response.data
                })
            })
    }

    onChange(e) {
        switch (e.target.id) {
            case 'companyFullname':
                this.setState({
                    ...this.state,
                    company: {
                        ...this.state.company,
                        display_name: e.target.value
                    }
                })
                break;
            case 'rut':
                this.setState({
                    ...this.state,
                    company: {
                        ...this.state.company,
                        rut_company: e.target.value
                    }
                })
                break;
            case 'sag':
                this.setState({
                    ...this.state,
                    company: {
                        ...this.state.company,
                        code_sag: e.target.value
                    }
                })
                break;
            default:
                break;
        }
    }

    handleCompanyTypeChange(e) {
        this.setState({
            company: {
                ...this.state.company,
                company_type_id: e.target.id
            },
            selectedCompanyType: e.target.id
        })
    }

    createFolder() {
        let self = this

        var formData = new FormData();

        formData.append("company_id", this.props.currentUser.selectedRole);
        formData.append("uploaded_by_id", 0);
        formData.append("code_sag", this.state.company.code_sag);
        formData.append("display_name", this.state.company.display_name);
        formData.append("rut_company", this.state.company.rut_company);

        axios.post(`${API_ENDPOINT}${'company/folder'}`, formData)
            .then(function (response) {
                //Asignar la key al documento en curso
                if (response.status === 200) {
                    self.setState({ displayFolderModal: false })
                    self.refreshFolders()
                }
                else {
                    //Mostrar error de upload
                }
            })
    }

    showCreationModal() {
        this.setState({ ...this.state, displayFolderModal: true })
    }

    showUploadModal(folder) {
        this.loadDocumentType(this.state.selectedTab)
        this.loadSpecies()
        this.setState({ ...this.state, selectedFolder: folder, displayUploadModal: true })
    }

    showDocumentsModal(folder) {
        let self = this
        axios.get(`${API_ENDPOINT}${'company/documents'}`,
            {
                params: {
                    folder_id: folder.id
                }
            }
        )
            .then(function (response) {
                folder.documents = response.data
                self.setState({ ...self.state, selectedFolder: folder, displayDocumentsModal: true })
            })
    }

    handleAddDocument() {
        if (this.state.selectedDocumentType.length > 0) {
            //Id del tipo de documento
            let selectedDocumentTypeId = parseInt(this.state.selectedDocumentType)

            //Id de la especie
            let selectedSpecieId = parseInt(this.state.selectedSpecieId)
            //Descripción asociada
            let selectDocumentTypeName = this.state.documentTypes.filter((type) => {
                return type.id === selectedDocumentTypeId
            })[0].display_name

            this.setState({
                ...this.state,
                selectedFolder: {
                    ...this.state.selectedFolder,
                    documents: [
                        ...this.state.selectedFolder.documents,
                        {
                            type: selectDocumentTypeName,
                            type_id: selectedDocumentTypeId,
                            name: '',
                            state: DOC_PENDING,
                            key: this.state.selectedFolder.documents.length,
                            specie_id: selectedSpecieId,
                            file: {}
                        }
                    ]
                }
            })
        }
    }

    handleFileChanged(event, documentKey) {
        //Selecionamos el archivo
        console.log(this.state.selectedFolder)
        var document = this.state.selectedFolder.documents.find((doc) => { return doc.key === documentKey })
        document.file = event.target.files[0]
    }

    handleFileUpload(documentKey) {
        var self = this
        var formData = new FormData();
        var document = this.state.selectedFolder.documents.find((doc) => { return doc.key === documentKey })

        formData.append("doc", document.file);
        formData.append("document_type", document.type_id);
        formData.append("doc_key", document.key);
        formData.append("folder_id", this.state.selectedFolder.id);
        formData.append("specie_id", document.specie_id);

        axios.post(`${API_ENDPOINT}${'company/documents'}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(function (response) {
            //Asignar la key al documento en curso
            if (response.status === 200) {
                document.key = response.data.key
                document.state = DOC_SENT
            }
            else {
                //Mostrar error de upload
            }
            self.showUploadModal(self.state.selectedFolder)
            self.refreshFolders()
        })
    }
    handleFileDelete(doc) {
        let self = this

        if (window.confirm("Desea eliminar el documento selecionado?")) {
            axios.delete(`${API_ENDPOINT}${'company/documents'}`, {
                params: { document_id: doc.id }
            }).then(function (response) {
                self.showDocumentsModal(self.state.selectedFolder)
                self.refreshFolders()
            }).catch((err) => {
                alert("Usted no tiene los derechos suficientes para eliminar este documento.")
            })
        }
    }

    handleFolderDelete(folder) {
        let self = this

        if (window.confirm("Desea eliminar la carpeta selecionada (y sus documentos)?")) {
            axios.delete(`${API_ENDPOINT}${'company/folder'}`, {
                params: { folder_id: folder.id }
            }).then(function (response) {
                self.refreshFolders()
            }).catch((err) => {
                alert("Usted no tiene los derechos suficientes para eliminar este documento.")
            })
        }
    }


    CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Box
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
            >
                {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
            </Box>
        );
    }

    render() {
        if (this.state.triggerRefresh) {
            this.setState(
                {
                    ...this.state,
                    triggerRefresh: false
                }
            );
        }

        //Recuperamos un listado de las empresas disponibles y de sus productores
        let displayedFolders = this.state.folders;

        let companyEntries = [];
        let growerEntries = [];

        const hasKeyInList = (list, target) => { return list.filter((x) => { return x.key === target }).length > 0 }

        for (var k in displayedFolders) {
            let company = displayedFolders[k].folder_owner;
            if (!hasKeyInList(companyEntries, company.id)) {
                companyEntries.push({ key: company.id, display: normalizeRut(company.rut) + " " + normalizeCase(company.display_name) });
            }

            let grower = displayedFolders[k];
            if (!hasKeyInList(growerEntries, grower.code_sag)) {
                growerEntries.push({ key: grower.code_sag, display: normalizeRut(grower.rut_company) + " " + normalizeCase(grower.display_name) });
            }
        }

        const updatedFilter = () => {
            return <DocumentFilter companyEntries={companyEntries} searchQuery={this.state.searchQuery} onRefreshResult={(options) => this.refreshFolders(options)} />
        }

        return (
            <div>
                <div>
                    {
                        this.state.loading && <GenericLoader openState={this.state.loading} title={"Buscando documentos..."} />
                    }
                    {!this.state.loading && <Box display={'flex'} sx={{ flexDirection: "column" }}>
                        <Tabs value={this.state.selectedTab} onChange={(e, newValue) => {
                            this.refreshFolders({ folderType: newValue });
                            this.loadDocumentType(newValue);
                        }} centered>
                            <Tab label="Documentación productor" value={"SAG_DOC"} />
                            <Tab label="Documentación despacho" value={"BL_DOC"} />
                            <Tab label="Otros" value={"OTRO"} />
                        </Tabs>
                        <this.CustomTabPanel value={this.state.selectedTab} index={"SAG_DOC"}>
                            <h5>Buscar documentos:</h5>
                            <div className="flex-row">
                                {
                                    this.state.folders.length > 0 && updatedFilter()
                                }
                            </div>
                            <table id="folderTable" className="table table-striped table-borderless table-light table-sm text-start my-4">
                                <caption>Carpetas disponibles</caption>
                                <thead>
                                    <tr>
                                        <th>Subido por</th>
                                        <th>Razón social</th>
                                        <th>RUT</th>
                                        <th>Código SAG</th>
                                        <th className="text-center">N° Documentos</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr key="-1">
                                        <td className="table-bg"><button type="button" className='btn btn-success btn-sm button-green' disabled={!this.state.canCreateFolder} onClick={() => { this.showCreationModal() }}>Crear nueva carpeta </button></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    {
                                        this.state.selectedTab === "SAG_DOC" && this.state.folders.map((x) => {
                                            return <tr key={x.id}>
                                                <td>{normalizeCase(x.folder_owner.display_name)}</td>
                                                <td >{normalizeCase(x.display_name)}</td>
                                                <td>{normalizeRut(x.rut_company)}</td>
                                                <td className="text-center" >{x.code_sag}</td>
                                                <td className="text-center">{x.br_documents.length}</td>
                                                <td><button type="button" className='btn btn-primary btn-sm button-blue'
                                                    disabled={!this.state.canUploadFile}
                                                    onClick={() => { this.showUploadModal(x) }}>Subir archivo </button></td>
                                                <td><button type="button" className='btn btn-success btn-sm button-green'
                                                    disabled={x.br_documents.length === 0}
                                                    onClick={() => { this.showDocumentsModal(x) }}>Ver </button></td>
                                                <td><button type="button" className='btn btn-danger btn-sm'
                                                    onClick={() => { this.handleFolderDelete(x) }}>Eliminar carpeta</button></td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </this.CustomTabPanel>
                        <this.CustomTabPanel value={this.state.selectedTab} index={"BL_DOC"}>
                            <table id="folderTable" className="table table-striped table-borderless table-light table-sm text-start my-4">
                                <caption>Carpetas disponibles</caption>
                                <thead>
                                    <tr>
                                        <th>Subido por</th>
                                        <th>Contenedor</th>
                                        <th className="text-center">N° Documentos</th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.selectedTab === "BL_DOC" && this.state.folders.map((x) => {
                                            return <tr key={x.id}>
                                                <td>{normalizeCase(x.folder_owner.display_name)}</td>
                                                <td >{normalizeCase(x.display_name)}</td>
                                                <td className="text-center">{x.br_documents.length}</td>
                                                <td><button type="button" className='btn btn-primary btn-sm button-blue'
                                                    disabled={!this.state.canUploadFile}
                                                    onClick={() => { this.showUploadModal(x) }}>Subir archivo </button></td>
                                                <td><button type="button" className='btn btn-success btn-sm button-green'
                                                    disabled={x.br_documents.length === 0}
                                                    onClick={() => { this.showDocumentsModal(x) }}>Ver </button></td>
                                                <td><button type="button" className='btn btn-danger btn-sm'
                                                    onClick={() => { this.handleFolderDelete(x) }}>Eliminar carpeta</button></td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </this.CustomTabPanel>
                    </Box>
                    }
                </div>

                <Modal show={this.state.displayFolderModal} onHide={() => this.setState({ ...this.state, displayFolderModal: false })}>
                    <Modal.Header>
                        <Modal.Title>Crear carpeta</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label>Razón social</label>
                            <input type="text" maxLength='100' className="form-control form-control-sm" id="companyFullname"
                                aria-describedby="companyFullnameHelp"
                                placeholder="Ingresar código de reserva"
                                onChange={(e) => { this.onChange(e) }} />
                            <small id="companyFullnameHelp" className="form-text text-muted">La razón social de la empresa tal como registrada en el SII.</small>
                        </div>
                        <br />
                        <div className="form-group">
                            <label>RUT</label>
                            <input type="text" maxLength='100' className="form-control form-control-sm" id="rut"
                                placeholder="XX.XXX.XXX-X"
                                onChange={(e) => { this.onChange(e) }} />
                        </div>
                        <br />
                        <div className="form-group">
                            <label>Código SAG</label>
                            <input type="text" maxLength='30' className="form-control form-control-sm" id="sag"
                                aria-describedby="companyShortNameHelp"
                                placeholder="XXXXXX"
                                onChange={(e) => { this.onChange(e) }} />
                            <small id="companyShortNameHelp" className="form-text text-muted"></small>
                        </div>
                        <br />

                        <div className="form-group">
                            <label>Tipo de empresa</label>
                            <br /><br />
                            <div className='flex-row'>
                                <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                    <input type="radio" className="btn-check" name="btnradio"
                                        value={1} autoComplete="off" checked={this.state.selectedCompanyType === '1'}
                                        onChange={() => { }} />
                                    <label className="btn btn-outline-primary" id={1}
                                        onClick={this.handleCompanyTypeChange.bind(this)}>Exportador</label>

                                    <input type="radio" className="btn-check" name="btnradio"
                                        value={2} autoComplete="off" checked={this.state.selectedCompanyType === '2'}
                                        onChange={() => { }} />
                                    <label className="btn btn-outline-primary" id={2}
                                        onClick={this.handleCompanyTypeChange.bind(this)}>Empacador</label>

                                    <input type="radio" className="btn-check" name="btnradio"
                                        value={3} autoComplete="off" checked={this.state.selectedCompanyType === '3'}
                                        onChange={() => { }} />
                                    <label className="btn btn-outline-primary" id={3}
                                        onClick={this.handleCompanyTypeChange.bind(this)}>Productor</label>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary btn-sm btn-gray " onClick={() => this.setState({ ...this.state, displayFolderModal: false })}>
                            Cerrar
                        </Button>
                        <Button variant="primary btn-sm button-blue" onClick={() => this.createFolder()}>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal size="lg" show={this.state.displayUploadModal} onHide={() => this.setState({ ...this.state, displayUploadModal: false })}>
                    <Modal.Header>
                        <Modal.Title>Subir archivo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            {
                                this.state.selectedTab == "SAG_DOC" && <p>Razón social</p>
                            }
                            {
                                this.state.selectedTab == "BL_DOC" && <p>Contenedor</p>
                            }

                            <input type="text" maxLength='100' className="form-control text-muted form-control-sm" id="companyFullname"
                                aria-describedby="companyFullnameHelp"
                                value={this.state.selectedFolder.display_name}
                                readOnly />
                            <small id="companyFullnameHelp" className="form-text text-muted ">La razón social de la empresa tal como registrada en el SII.</small>
                        </div>
                        <br />
                        <div className="form-group">
                            <p>Fruta vinculada</p>
                            <select id="specieId" className="form-control form-control-sm mb-2" aria-describedby='documentTypeHelp' onChange={(e) => this.setState({ selectedSpecieId: e.target.value })}>
                                <option value={''}>---</option>
                                {
                                    this.state.species.map((state, k) => {
                                        return (
                                            <option value={state.id}> {state.name} </option>
                                        )
                                    })
                                }
                            </select>
                            <p>Tipo de archivo</p>
                            <select id="documentType" className="form-control form-control-sm" aria-describedby='documentTypeHelp' onChange={(e) => this.setState({ selectedDocumentType: e.target.value })}>
                                <option value={''}>---</option>
                                {
                                    this.state.documentTypes.map((state, k) => {
                                        return (
                                            <option value={state.id}> {state.display_name} </option>
                                        )
                                    })
                                }
                            </select>
                            <button type='button' className="btn btn-success btn-sm button-green my-2" onClick={() => { this.handleAddDocument() }}> <FontAwesomeIcon icon={faPlus} /> </button>
                        </div>
                        <div className="form-group my-2">
                            {
                                this.state.selectedFolder.documents &&
                                this.state.selectedFolder.documents.length === 0 && <div className="alert alert-warning text-alert" role="alert">
                                    {"No hay documentos asociados a esa carpeta."}
                                </div>
                            }

                            {
                                this.state.selectedFolder.documents &&
                                this.state.selectedFolder.documents.map((doc, k) => {
                                    return (
                                        <div className='input-group'>
                                            <Document key={k} documentName="" documentType={doc.type} documentState={doc.state} documentKey={doc.key} onFileChanged={this.handleFileChanged.bind(this)} />
                                            {
                                                doc.state === DOC_PENDING &&
                                                <button type='button' className='btn btn-info btn-sm' onClick={() => { this.handleFileUpload(doc.key) }} ><FontAwesomeIcon icon={faUpload} /></button>
                                            }
                                            {
                                                doc.state === DOC_SENT &&
                                                <button type='button' className='btn btn-success btn-sm button-green ' onClick={() => { this.handleFileUpload(doc.key) }} disabled><FontAwesomeIcon icon={faUpload} /></button>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary btn-sm" onClick={() => this.setState({ ...this.state, displayUploadModal: false })}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal size="lg" show={this.state.displayDocumentsModal} onHide={() => this.setState({ ...this.state, displayDocumentsModal: false })}>
                    <Modal.Header>
                        <Modal.Title>Documentos</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            {
                                this.state.selectedTab == "SAG_DOC" && <p>Razón social</p>
                            }
                            {
                                this.state.selectedTab == "BL_DOC" && <p>Contenedor</p>
                            }

                            <input type="text" maxLength='100' className="form-control text-muted" id="companyFullname"
                                aria-describedby="companyFullnameHelp"
                                value={this.state.selectedFolder.display_name}
                                readOnly />
                            <small id="companyFullnameHelp" className="form-text text-muted">La razón social de la empresa tal como registrada en el SII.</small>
                        </div>
                        <br />
                        <div className="form-group">
                            {
                                this.state.selectedFolder.documents &&
                                this.state.selectedFolder.documents.length === 0 && <div className="alert alert-warning" role="alert">
                                    {"No hay documentos asociados a esa carpeta."}
                                </div>
                            }
                            <table className="table table-sm table-hover table-borderless table-striped">
                                <thead>
                                    <th>Fruta</th>
                                    <th>Tipo documento</th>
                                    <th colspan="2"></th>
                                </thead>
                                <tbody>
                                    {
                                        this.state.selectedFolder.documents &&
                                        this.state.selectedFolder.documents.map((doc, k) => {
                                            return (
                                                <Document document={doc} display="row" onDelete={(d) => { this.handleFileDelete(d) }} />
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary btn-sm" onClick={() => this.setState({ ...this.state, displayDocumentsModal: false })}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(store) {
    return {
        currentUser: store.currentUser,
        companies: store.companies
    }
}

const mapDispatchToProps = {
    doLogOut
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentForm);
