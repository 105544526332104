import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';

export default function ReservationContainerDialog({onClose, openState, assetId}) {
  const [open, setOpen] = React.useState(openState);
  const [contenedor, setContenedor] = React.useState("");

  React.useEffect(() => { setOpen(openState)}, [openState, assetId]);


  const handleClose = (status:number, container:string) => {

    setOpen(false);
    if (typeof onClose !== 'undefined')
    {
      onClose(status, container);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Ingrese el numero de contenedor asociado a la reserva #" + assetId}
        </DialogTitle>
        <DialogContent>
        <TextField variant='outlined' placeholder='Contenedor' label="Contenedor" value={contenedor} onChange={(e) => setContenedor(e.target.value)}/>
        </DialogContent>
        <DialogActions>
        <Button onClick={() => {
          handleClose(0, "")
        }
      } autoFocus>
          {"Salir"}
        </Button>
          <Button onClick={() => {
            handleClose(1, contenedor)
          }
        }>{"Confirmar"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
