import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setLayout, exporterGetBoadring } from "../actions";
import GenericLoader from "./generic-loader";
import GenericBoardingReport from "./generic-boarding-report";
import { API_ENDPOINT } from "../constants";
import axios from "axios";

export default function BoardingReport({ boardingId }) {
  const dispatch = useDispatch();
  const company = useSelector(
    (state) => state.currentUser.selectedRoleDisplay.company
  );
  const companyId = company.id;
  const boarding = useSelector((state) => state.boarding.currentBoarding);
  const [companyLogo, setLogo] = useState("broken.png");

  const [isLoading, setLoading] = useState(boarding.id > 0);

  const loadCompanyLogo = () => {
    //Optenemos el logo de la empresa
    axios
      .get(`${API_ENDPOINT}${"company/"}${companyId}${"/logo"}`)
      .then(function (response) {
        setLogo(response.data.url);
      })
      .catch(() => setLogo("broken.png"));
  };

  useEffect(() => {
    dispatch(setLayout("print"));
    dispatch(exporterGetBoadring(companyId, boardingId));
    loadCompanyLogo();
  }, [boardingId]);

  return (
    <div style={{ left: 0, position: "absolute" }}>
      <GenericLoader
        title={"Loading boarding..."}
        message={""}
        cancel={""}
        buttons={{ cancel: "Cancelar" }}
        openState={isLoading}
      />
      {!isLoading && <GenericBoardingReport boarding={boarding} companyLogo={companyLogo} /> }
    </div>
  );
}
